import React from "react";
import { fixPrice } from "../../utils/number";
import "./invoice.module.css";

export default function Invoice({ reff, order }) {
 return (
  <div ref={reff} style={{ padding: 30 }} className="print-font-family">
   <table>
    <tbody>
     <tr>
      <td colSpan={2} style={{ textAlign: "center", paddingBottom: 40 }}>
       <img src={`${process.env.REACT_APP_WEB_URL}/assets/images/logo-icon.svg`} alt="Icon" />
      </td>
     </tr>
     <tr>
      <td style={{ borderBottom: "2px solid #f0f0f0", width: "50%" }}>
       <h3 style={{ fontSize: 14, fontWeight: 600 }}>Detajet e porosisë:</h3>
       <p style={{ fontSize: 13 }}>Porosia: #{order.order_id}</p>
       <p style={{ fontSize: 13 }}>Data e porosisë: {new Date(order.timestamp * 1000).toLocaleString()}</p>
       <p style={{ fontSize: 13 }}>Menyra e pagesës: {order.payment_method.payment}</p>
       <p style={{ fontSize: 13, marginBottom: 25 }}>Menyra e transportit: {order.shipping[0].shipping}</p>
      </td>
      <td style={{ borderBottom: "2px solid #f0f0f0", width: "50%" }}>
       <h3 style={{ fontSize: 14, fontWeight: 600 }}>Informacioni i klientit:</h3>
       <p style={{ fontSize: 13 }}>Emri dhe Mbiemri: {`${order.firstname} ${order.lastname}`}</p>
       <p style={{ fontSize: 13 }}>Adresa: {order.s_address}</p>
       <p style={{ fontSize: 13 }}>Qyteti: {order.s_city}</p>
       <p style={{ fontSize: 13, marginBottom: 25 }}>Numri i Telefonit: {order.fields["37"]}</p>
      </td>
     </tr>

     <tr>
      <td colSpan={2} style={{ paddingTop: 20, paddingBottom: 20, borderBottom: "2px solid #f0f0f0" }}>
       <table>
        <tbody>
         <tr>
          <th style={{ fontSize: 13, paddingBottom: 15 }} colSpan={2}>
           Produkti
          </th>
          <th style={{ fontSize: 13, paddingBottom: 15 }}>Cmimi</th>
          <th style={{ fontSize: 14, paddingBottom: 15 }}>Sasia</th>
          <th style={{ fontSize: 14, paddingBottom: 15 }}>Totali</th>
         </tr>

         {Object.keys(order.products).map((item) => {
          const product = order.products[item];
          return (
           <tr key={product.product_id}>
            <td style={{ width: 100, paddingRight: 20, paddingBottom: 15 }}>
             <img style={{ width: "100%" }} src={product?.main_pair.detailed?.image_path} alt="Icon" />
            </td>
            <td style={{ width: "280px", paddingBottom: 15 }}>
             <a href="" style={{ fontSize: 13 }}>
              {product.product}
             </a>
            </td>
            <td style={{ fontSize: 13, textAlign: "center", paddingBottom: 15, whiteSpace: "nowrap" }}> {fixPrice(product.price)} &euro; </td>
            <td style={{ fontSize: 13, textAlign: "center", paddingBottom: 15 }}> {product.amount} </td>
            <td style={{ fontSize: 14, textAlign: "center", paddingBottom: 15, whiteSpace: "nowrap" }}>
             {" "}
             <h3 style={{ color: "#26A9E0", fontWeight: 600 }}>{fixPrice(product.subtotal)} &euro;</h3>{" "}
            </td>
           </tr>
          );
         })}
        </tbody>
       </table>
      </td>
     </tr>

     <tr>
      <td style={{ width: "50%", paddingBottom: 20, paddingTop: 15, paddingRight: 50 }}>
       <h3 style={{ fontSize: 14, fontWeight: 600, paddingBottom: 20, borderBottom: "2px solid #f0f0f0" }}>CUSTOMER NOTES</h3>
       <p style={{ paddingTop: 20, fontSize: 12 }}>{order.notes}</p>
      </td>
      <td style={{ width: "50%" }}>
       <table>
        <tbody>
         <tr>
          <td style={{ fontSize: 13, paddingBottom: 12, paddingTop: 20 }}>Nentotali</td>
          <td style={{ fontSize: 14, textAlign: "right", paddingBottom: 12, paddingTop: 20, whiteSpace: "nowrap" }}>
           {fixPrice(order.subtotal)} &euro;
          </td>
         </tr>
         <tr>
          <td style={{ fontSize: 13, paddingBottom: 12 }}>Transporti</td>
          <td style={{ fontSize: 14, textAlign: "right", paddingBottom: 12, whiteSpace: "nowrap" }}>0.00 &euro;</td>
         </tr>

         {order.subtotal_discount ? (
          <tr>
           <td style={{ fontSize: 13, paddingBottom: 15 }}>Kuponi</td>
           <td style={{ fontSize: 14, textAlign: "right", paddingBottom: 15, whiteSpace: "nowrap" }}>-{fixPrice(order.subtotal_discount)} &euro;</td>
          </tr>
         ) : null}
         <tr>
          <td style={{ fontSize: 16, fontWeight: 600, borderTop: "2px solid #f0f0f0", paddingTop: 15 }}>Totali</td>
          <td style={{ textAlign: "right", paddingTop: 15, fontWeight: 600, fontSize: 16, color: "#26A9E0", borderTop: "2px solid #f0f0f0" }}>
           {fixPrice(order.total)} &euro;
          </td>
         </tr>
        </tbody>
       </table>
      </td>
     </tr>

     <tr>
      <td colSpan={2} style={{ fontSize: 12, paddingTop: 30, textAlign: "center" }}>
       Faleminderit që keni zgjedhur <b>Buton</b> © powered by <b>PC COMP sh.p.k</b>
      </td>
     </tr>
    </tbody>
   </table>
  </div>
 );
}
