import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getDeals } from "../../redux/app/deals/actions";
import { getBanners } from "../../redux/app/banners/actions";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { fixPrice } from "../../utils/number";
import Loader from "../Loader";

export default function Banner() {
 const dispatch = useDispatch();
 const { deals, isLoading } = useSelector((state) => state.deals);
 const { banners } = useSelector((state) => state.banners);

 useEffect(() => {
  dispatch(getDeals());
  dispatch(getBanners());
 }, [dispatch]);

 var settings = {
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  fade: true,
  rows: 1,
  speed: 500,
  initialSlide: 0,
  lazyLoad: true,
  arrows: false,
 };

 return (
  <>
   {isLoading && <Loader />}
   {!isLoading && deals && (
    <Slider {...settings}>
     {banners?.map((item) => (
      <div className="banner" key={item?.banner_id}>
       <div className="info">
        {/* <h1>{item.promo_text.replace(/(<([^>]+)>)/gi, "")}</h1> */}
        {/* <p>{item.product}</p> */}
        {/* <h3 className="price">{fixPrice(item.price)} &euro;</h3> */}
        {/* <Link
                      to={`/product/${item.product_id}`}
                      className="btn blue"
                    >
                      Shiko produktin
                    </Link> */}
        {/* <div className='brand-logo'>
                      <img src='/assets/images/delllogo.svg' alt="Logo" />
                      <p>Partnership</p>
                    </div> */}
        <div className="image">
         <Link to={item.url}>
          <img src={item?.main_pair?.icon?.image_path} alt={item?.banner} />
         </Link>
        </div>
       </div>
      </div>
     ))}
    </Slider>
   )}
  </>
 );
}
