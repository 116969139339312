import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { ErrorMessage } from "../Custom";
import Loader from "../Loader";
import Button from "../Button";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../redux/app/profile/actions";

export default function UpdateProfile() {
 const dispatch = useDispatch();
 const { user, isSubmitting } = useSelector((state) => state.profile);

 const ProfileSchema = Yup.object().shape({
  name: Yup.string().required("Emri eshte i detyrueshem"),
  lastname: Yup.string().required("Mbiemri eshte i detyrueshem"),
  region: Yup.string().required("Shteti eshte i detyrueshem"),
  address: Yup.string().required("Adresa eshte e detyrueshme"),
  city: Yup.string().required("Qyteti eshte i detyrueshem"),
  phone: Yup.string().required("Numri i telefonit eshte i detyrueshem"),
  email: Yup.string().email().required("Email eshte e detyrueshme"),
  s_address: Yup.string().required("Adresa e transportit eshte e detyrueshme"),
  s_city: Yup.string().required("Qyteti i transportit eshte i detyrueshem"),
 });

 return !user ? (
  <Loader />
 ) : (
  <Formik
   initialValues={{
    name: user.fields["C"].fields[0].value || "",
    lastname: user.fields["C"].fields[1].value || "",
    region: "AL",
    address: user.fields["S"].fields[2].value || "",
    city: user?.fields?.["S"]?.fields[4].value || "",
    phone: user.phone || "",
    email: user.fields["E"].fields[0].value || "",
    s_address: user.fields?.["S"].fields[2].value || "",
    detailed_address: user?.fields?.["S"]?.fields[3]?.value || "",
    s_city: user.fields["S"].fields[4].value || "",
    company: user.fields["C"].fields[2].value || "",
   }}
   validationSchema={ProfileSchema}
   onSubmit={(values) => {
    dispatch(updateUser(values, user));
   }}
  >
   {({ errors, touched }) => (
    <Form>
     <div className="form">
      <div className="form-row">
       <div className="form-group">
        <label htmlFor="name">Emri*</label>
        <Field type="text" name="name" placeholder="Shkruaj Emrin" />
        {errors.name && touched.name && <ErrorMessage message={errors.name} />}
       </div>
       <div className="form-group">
        <label htmlFor="lastname">Mbiemri*</label>
        <Field type="text" name="lastname" placeholder="Shkruaj Mbiemrin" />
        {errors.lastname && touched.lastname && <ErrorMessage message={errors.lastname} />}
       </div>
      </div>

      <div className="form-row">
       <div className="form-group">
        <label htmlFor="email">Email adresa*</label>
        <Field type="text" name="email" placeholder="Shkruaj Emailin" />
        {errors.email && touched.email && <ErrorMessage message={errors.email} />}
       </div>
       <div className="form-group">
        <label htmlFor="phone">Numri telefonit*</label>
        <Field type="text" name="phone" placeholder="Shkruaj Numrin e Telefonit" />
        {errors.phone && touched.phone && <ErrorMessage message={errors.phone} />}
       </div>
      </div>

      <div className="form-row">
       <div className="form-group">
        <label htmlFor="region">Shteti / Regjioni*</label>
        <Field as="select" type="text" name="region" placeholder="Zgjedh Ragjionin">
         <option value="AL">Kosova</option>
        </Field>
        {errors.region && touched.region && <ErrorMessage message={errors.region} />}
       </div>
       <div className="form-group">
        <label htmlFor="city">Qyteti*</label>
        <Field type="text" name="city" placeholder="Shkrauj Qytetin" />
        {errors.city && touched.city && <ErrorMessage message={errors.city} />}
       </div>
      </div>

      <div className="form-row">
       <div className="form-group">
        <label htmlFor="address">Adresa*</label>
        <Field type="text" name="address" placeholder="Shkruaj Adresen" />
        {errors.address && touched.address && <ErrorMessage message={errors.address} />}
       </div>
      </div>
      <div className="form-row">
       <div className="form-group">
        <label htmlFor="company">Nr i biznesit</label>
        <Field type="text" name="company" placeholder="Shkruaj Nr e biznesit" />
        {errors.company && touched.company && <ErrorMessage message={errors.company} />}
       </div>
      </div>

      {/* Address details */}

      <h4 className="sub-title">Detajet e transportit</h4>
      <div className="form-row">
       <div className="form-group">
        <label htmlFor="s_city">Qyteti*</label>
        <Field type="text" name="s_city" placeholder="Shkruaj Qytetin" />
        {errors.s_city && touched.s_city && <ErrorMessage message={errors.s_city} />}
       </div>
       <div className="form-group">
        <label htmlFor="s_address">Adresa*</label>
        <Field type="text" name="s_address" placeholder="Shkruaj Adresen" />
        {errors.s_address && touched.s_address && <ErrorMessage message={errors.s_address} />}
       </div>
      </div>

      <div className="form-row">
       <div className="form-group">
        <label htmlFor="detailed_address">Adresa e detajuar (opcional)</label>
        <Field as="textarea" rows="4" type="text" name="detailed_address" placeholder="Shkruaj Adresen e detajuar" />
       </div>
      </div>

      <div className="d-flex justify-center">
       <Button type="submit" classes="green continue" title="Ruaj" loading={isSubmitting} />
      </div>
     </div>
    </Form>
   )}
  </Formik>
 );
}
