import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ErrorMessage } from "../Custom";
import Button from "../Button";
import { useDispatch, useSelector } from "react-redux";
import { updateUser, clearSuccess } from "../../redux/app/profile/actions";
import { setNotes } from "../../redux/app/orders/actions";

export default function OrderDetails({ step, setStep }) {
 const dispatch = useDispatch();
 const { notes } = useSelector((state) => state.orders);
 const { user, isSubmitting, success } = useSelector((state) => state.profile);

 const BuySchema = Yup.object().shape({
  name: Yup.string().required("Emri eshte i detyrueshem"),
  lastname: Yup.string().required("Mbiemri eshte i detyrueshem"),
  address: Yup.string().required("Adresa e transportit eshte e detyrueshme"),
  city: Yup.string().required("Qyteti i transportit eshte i detyrueshem"),
  phone: Yup.string().required("Numri i telefonit eshte i detyrueshem"),
  s_address: Yup.string().required("Adresa e transportit eshte e detyrueshme"),
  s_city: Yup.string().required("Qyteti i transportit eshte i detyrueshem"),
 });

 const formik = useFormik({
  initialValues: {
   name: user?.fields["C"].fields[0].value || "",
   lastname: user?.fields["C"].fields[1].value || "",
   region: "AL",
   address: user.fields["S"].fields[2].value || "",
   city: user?.fields?.["S"]?.fields[4].value || "",
   phone: user?.phone || "",
   email: user?.fields["E"].fields[0].value || "",
   s_address: user?.fields["S"].fields[2].value || "",
   s_city: user?.fields["S"].fields[4].value || "",
   notes: notes || "",
  },
  validationSchema: BuySchema,
  enableReinitialize: true,
  onSubmit: (values) => {
   dispatch(updateUser(values, user));
   dispatch(setNotes(values.notes));
  },
 });

 useEffect(() => {
  if (!isSubmitting && success) setStep(2);
  return () => dispatch(clearSuccess());
 }, [isSubmitting, success]);

 const isValid = () => {
  if (!user?.fields["B"].fields[3].value) return false;
  if (!user?.fields["B"].fields[5].value) return false;
  if (!user?.fields["B"].fields[0].value) return false;
  if (!user?.fields["S"].fields[2].value) return false;
  if (!user?.fields["S"].fields[4].value) return false;
  return true;
 };

 return (
  <>
   <div className="tabs">
    <span className={step === 1 ? `active` : ""} onClick={() => setStep(1)}>
     Detajet e fatures
    </span>
    <span className={step === 2 ? `active` : ""} onClick={() => isValid() && setStep(2)}>
     Permbledhja e porosise
    </span>
   </div>

   <form onSubmit={formik.handleSubmit}>
    <div className="form">
     <div className="form-row">
      <div className="form-group">
       <label htmlFor="name">Emri*</label>
       <input
        type="text"
        id="name"
        name="name"
        placeholder="Shkruaj Emrin"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.name}
       />
       {formik.errors.name && formik.touched.name && <ErrorMessage message={formik.errors.name} />}
      </div>
      <div className="form-group">
       <label htmlFor="lastname">Mbiemri*</label>
       <input
        type="text"
        id="lastname"
        name="lastname"
        placeholder="Shkruaj Mbiemri"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.lastname}
       />
       {formik.errors.lastname && formik.touched.lastname && <ErrorMessage message={formik.errors.lastname} />}
      </div>
     </div>

     <div className="form-row">
      <div className="form-group">
       <label htmlFor="phone">Numri telefonit*</label>
       <input
        type="text"
        id="phone"
        name="phone"
        placeholder="Shkruaj Numrin e Telefonit"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.phone}
       />
       {formik.errors.phone && formik.touched.phone && <ErrorMessage message={formik.errors.phone} />}
      </div>

      <div className="form-group">
       <label htmlFor="city">Qyteti*</label>
       <input
        type="text"
        id="city"
        name="city"
        placeholder="Shkruaj Qytetin"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.city}
       />
       {formik.errors.city && formik.touched.city && <ErrorMessage message={formik.errors.city} />}
      </div>
     </div>

     <div className="form-row">
      <div className="form-group">
       <label htmlFor="address">Adresa*</label>
       <input
        type="text"
        id="address"
        name="address"
        placeholder="Shkruaj Adresen"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.address}
       />
       {formik.errors.address && formik.touched.address && <ErrorMessage message={formik.errors.address} />}
      </div>
     </div>

     {/* Address details */}

     <h4 className="sub-title">Detajet e transportit</h4>
     <div className="form-row">
      <div className="form-group">
       <label htmlFor="s_city">Qyteti*</label>
       <input
        type="text"
        id="s_city"
        name="s_city"
        placeholder="Shkruaj Qytetin"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.s_city}
       />
       {formik.errors.s_city && formik.touched.s_city && <ErrorMessage message={formik.errors.s_city} />}
      </div>
      <div className="form-group">
       <label htmlFor="s_address">Adresa*</label>
       <input
        type="text"
        id="s_address"
        name="s_address"
        placeholder="Shkruaj Adresen"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.s_address}
       />
       {formik.errors.s_address && formik.touched.s_address && <ErrorMessage message={formik.errors.s_address} />}
      </div>
     </div>

     <div className="form-row">
      <div className="form-group">
       <label htmlFor="notes">Shenimet e porosise (opcional)</label>
       <textarea
        rows="5"
        id="notes"
        name="notes"
        placeholder="Shkruaj Shenimet e porosise (opcional)"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.notes}
       ></textarea>
      </div>
     </div>

     <div className="d-flex justify-center">
      <Button type="submit" classes="green continue" title="Ruaj" loading={isSubmitting} />
     </div>
    </div>
   </form>
  </>
 );
}
