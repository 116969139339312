import { put, select, takeLatest } from "redux-saga/effects";
import { setUser, updateSubmitting, getUser } from "./actions";
import { setMessage } from "../custom/actions";
import { CHANGE_PASSWORD, GET_USER, REQUEST_RESET_PASSWORD, UPDATE_USER } from "./types";
import api, { user_api } from "../../../utils/axios";

// watcher
export function* profileWatcher() {
 yield takeLatest(CHANGE_PASSWORD, CHANGEPASSWORD);
 yield takeLatest(GET_USER, GETUSER);
 yield takeLatest(UPDATE_USER, UPDATEUSER);
 yield takeLatest(REQUEST_RESET_PASSWORD, REQUESTRESETPASSWORD);
}

// worker
export function* GETUSER() {
 try {
  const user = (yield user_api.get(`/4.0/sra_profile`)).data;
  yield put(setUser(user));
 } catch (e) {
  console.log(e);
 }
}

export function* UPDATEUSER({ user, _user }) {
 try {
  yield user_api.put(`/4.0/sra_profile/${_user.user_id}`, {
   email: user.email,
   firstname: user.name,
   lastname: user.lastname,
   b_address: user.address,
   b_city: user.city,
   phone: user.phone,
   s_firstname: user.name,
   s_lastname: user.lastname,
   s_address: user.s_address,
   s_address_2: user.detailed_address,
   s_city: user.s_city,
   company: user.company,
  });

  yield put(setMessage("success", "Profili u perditesua me suksese."));
  yield put(getUser());
  yield put(updateSubmitting(false, true));
 } catch (e) {
  yield put(updateSubmitting(false, false));
  yield put(setMessage("warning", e.response.data.message || "Kishte nje problem, provoni perseri!"));
 }
}

export function* CHANGEPASSWORD({ data }) {
 try {
  const user = yield select((state) => state.auth.user);
  yield user_api.put(`/4.0/sra_change_password/${user.id}`, {
   email: user.email,
   lastPassword: data.password,
   password: data.newPassword,
  });
  yield put(updateSubmitting());
  yield put(setMessage("success", "Fjalekalimi u ndryshua!"));
 } catch (e) {
  yield put(updateSubmitting());
  yield put(setMessage("error", e.response.data.message || "Kishte nje problem, provoni perseri!"));
 }
}

export function* REQUESTRESETPASSWORD({ email }) {
 try {
  yield api.post(`/4.0/sra_recover_password`, {
   email,
  });
  yield put(updateSubmitting());
  yield put(setMessage("info", "Ju lutem shikoni emailin tuaj!"));
 } catch (e) {
  yield put(updateSubmitting());
  yield put(setMessage("error", "Emaili nuk ekziton!"));
 }
}
