import { put, takeLatest, select } from "redux-saga/effects";
import { setOrders, createOrderResponse } from "./actions";
import { GET_ORDERS, CREATE_ORDER } from "./types";
import { user_api } from "../../../utils/axios";
import { setMessage } from "../custom/actions";
import { clearCartDB, clearCoupon } from "../cart/actions";

// watcher
export function* ordersWatcher() {
 yield takeLatest(GET_ORDERS, GETORDERS);
 yield takeLatest(CREATE_ORDER, CREATEORDER);
}

// worker
export function* GETORDERS() {
 try {
  const { orders } = (yield user_api.get(`/orders?get_orders_data=true&items_per_page=`)).data;
  yield put(setOrders(orders));
 } catch (e) {
  console.log(e);
  yield put(setMessage("Kishte nje problem, provoni perseri!"));
 }
}

// worker
export function* CREATEORDER({ order }) {
 try {
  const products = yield select((state) => state.cart.cartProducts);
  const coupon = yield select((state) => state.cart.coupon);
  let productsObject = {};
  products.map(
   (item) =>
    (productsObject[item.id] = {
     product_id: item.product_id,
     amount: item.quantity,
    })
  );

  if (coupon) order.coupon_codes = [coupon.name];
  order.products = productsObject;
  const result = (yield user_api.post(`/orders`, order)).data;
  yield put(createOrderResponse(true, result.order_id));
  yield put(clearCartDB());
  yield put(clearCoupon());
 } catch (e) {
  console.log(e);
  yield put(createOrderResponse(false, null));
  yield put(setMessage("warning", e.response.data.message || "Kishte nje problem"));
 }
}
